$blue: #002c50;
$dark-blue: #00213c;
$light-blue: #073c65;
$lighter-blue: #257bb9;
$orange: #ffc600;
$brownish: #5e4900;
$black: #44525e;
$almost-white: #cdddeb;

.navbar-toggler{
    border: 2px solid #FFF!important;
    width: 50px;
    height: 47px;
    cursor: pointer;
    padding: 0;
    position: relative;
    align-self:center;

    span{
        display: block;
        width: 80%;
        height: 3px;
        margin: 0 auto;
        margin-bottom: 8px;
        background-color: #FFF;
        background-image: none;
        outline:solid 1px transparent;
        transition:all .16s ease-in-out;
        transform: rotate(0) translate(0);

        &:first-child{
            margin-top:8px;
        }
    }

    &.active{
        span{
            transform: rotate(-45deg);
            transform-origin:center;


            &:first-child{
                transform: rotate(45deg) translate(4px, -6px);
                transform-origin:left top;
            }
            &:last-child{
                transform: rotate(45deg) translate(-4px,  5px);
                transform-origin:right bottom;
            }
        }
    }
}

.dropdown-toggle{
    display: flex;
    width: 72px;
    height: 72px;
    position: absolute;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;

    &:after{
        color: #FFF;
    }
}
//md
@media screen and (max-width:991px){
    header{
        .bottom{
            position: relative;
            z-index: 9;
            height: 80px;

            >.container{
                padding: 0;
                height: 100%;

                >.row{
                    height: 100%;
                }
            }
            .navigation-wrap{
                position: relative;
                display: flex;
                justify-content: flex-end;
                width: 100%;
                top:0;
                align-self:stretch;
                align-content: center;

                .navbar{
                    width: 100%;
                    position: absolute;
                    background-color: $blue;
                    top: 100%;
                    z-index: 1;
                    max-height:70vh;
                    overflow: auto;
                    right: 0;

                    .navbar-nav{
                        display: block;
                    }

                    .nav-link{
                        justify-content: flex-end;
                        padding-left: 50px;
                    }

                    .dropdown{
                        position: relative;
                        opacity: 1;
                        transform: scaleY(1);
                        width: 100%;
                        max-width: none;
                        background-color: #113858;
                        display: none;

                        .nav-link{
                            background-color: #113858;
                            text-align: right;
                        }
                    }
                }

                &.clicked{
                    .overlay{
                        transform: scale(1);
                    }
                }
            }

            .overlay{
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                transform: scale(0);
            }
        }

        .logo-wrap{
            padding: 5px 0;
            max-width: 75px;
            a{
                position: relative;
                transform: none;
                top: auto;
                bottom: auto;

                img{
                    height: 70px;
                }
            }
        }

        .title-wrap{
            position: relative;
            height: 100%;
        }

        &+section:not(.carousel){
            margin-top: 30px;
        }
    }

    .container{
        width: 90%;
        max-width: 90%;
    }
}
//sm
@media screen and (max-width:767px){
    .left-content{
        max-width: unset;
        margin-right: 0;
    }
}
@media screen and (max-width:691px){

}
//xs
@media screen and (max-width:575px){
    .news{
        padding: 20px 15px;

        article{
            .img{
                margin: 0 auto;
                padding-right: 0;
                margin-bottom: 20px;
            }
            .content{
                min-width: 100%;
            }
        }
    }
    header.container-fluid{
        position: sticky;
        top: 0;
    }
}

@media screen and (max-width:480px){
    header{
        .top{
            .container{
                padding: 0;
                .login{
                    margin-left: 10px;
                }


            }
        }
        .bottom{
            height: 80px;

            .row{
                height: 100%;
            }
        }
        .logo-wrap{
            padding-left: 10px;
            position: absolute;
        }
    }

    .bnrs{
        .bnr{
            .content{
                font-size: 7vw!important;
                line-height: 1.2em;

                span{
                    font-size: 10vw !important;
                }
            }
        }
    }

    footer{
        .top{
            .nav-item{
                width: 100%;
                padding-left: 0;
            }
            .soc{
                margin-bottom: 10px;
            }
        }
        .bottom{
            .left{
                padding-left: 0;

                .copy{
                    margin-top: 30px;
                }
            }
        }
    }
}